import { Controller } from '@hotwired/stimulus';

const checkedAmountSelector       = 'input[type="radio"][name$="[amount]"]:checked';
const annualMembershipMultiplier  = 9.6;
const workingButtonLabel          = 'Processing...';

// --------------------------------------------------------
// Utilities
// --------------------------------------------------------

function formattedAmount(amount, isAnnual, discounts) {
  const dollarAmount    = parseInt(amount) / 100;

  // Use case for discountAlreadyApplied to be true:
  // When form is shown on /crowdsponsor/earlybird, and the amount selected is $10
  // If we're already giving them a discount, then we multiply by 12 not 9.6
  // 
  // The discount array (defined in discounted_membership_form_component.rb) looks like this:
  // [
  //   {
  //     "from": 1500,
  //     "to": 1000,
  //   }
  // ]
  //
  // Check if the amount matches any 'to' value in the discounts array
  const discountAlreadyApplied = discounts.some(discount => discount.to.toString() === amount);

  const multiplier = isAnnual ? (discountAlreadyApplied ? 12 : annualMembershipMultiplier) : 1;

  const totalAmount     = dollarAmount * multiplier;
  
  return totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

// --------------------------------------------------------
// Controller Definition
// --------------------------------------------------------

export default class extends Controller {
  static targets = ['amountPicker', 'donationTypeCheckbox', 'submitButton', 'annualSaving'];

  static values = {
    buttonDisabled: { type: Boolean, default: false },
    buttonLabelBase:      String,
    buttonText:           String,
    selectedAmount:       String,
    isAnnualSubscription: Boolean,
    confirmation:         Boolean,
    stripeTokenId:        String,
    fmfCheckout:          Boolean,
    discounts:            Array
  }

  amountPickerTargetConnected(picker) {
    const defaultValue = picker.querySelector(checkedAmountSelector)?.value;

    if(defaultValue) {
      this.selectedAmountValue = defaultValue;
    }

    this.updateAnnualSavingVisibility()
  }

  onAmountChanged(event) {
    this.selectedAmountValue = event.target.value;
    this.updateAnnualSavingVisibility()
  }

  onDonationTypeChanged(event) {
    this.isAnnualSubscriptionValue = event.target.checked;
    this.updateAnnualSavingVisibility()
  }

  onConfirmationChanged(event) {
    this.confirmationValue = event.target.checked;
  }

  onTokenRequested() {
    this.stripeTokenIdValue = null;
  }

  onTokenReceived(evt) {
    this.stripeTokenIdValue = evt.detail?.token?.id;
  }

  onSubmit(evt) {
    if(this.buttonDisabledValue) {
      evt.preventDefault();
    }

    this.buttonDisabledValue  = true;
    this.buttonTextValue      = workingButtonLabel;
  }

  buttonDisabledValueChanged(disabled) {
    this.submitButtonTarget.disabled = disabled;
  }

  selectedAmountValueChanged(amount) {
    if(amount) {
      const currencyAmount = formattedAmount(amount, this.isAnnualSubscriptionValue, this.discountsValue);
      this.updateButtonLabel(currencyAmount);
    }
  }

  isAnnualSubscriptionValueChanged(isAnnual) {
    if(this.selectedAmountValue) {
      const currencyAmount = formattedAmount(this.selectedAmountValue, isAnnual, this.discountsValue);
      this.updateButtonLabel(currencyAmount);
    }
  }

  confirmationValueChanged() {
    this.updateButtonDisabledState();
  }

  stripeTokenIdValueChanged() {
    this.updateButtonDisabledState();
  }

  buttonTextValueChanged(label) {
    this.submitButtonTarget.value = label;
  }

  updateButtonLabel(formattedAmount) {
    this.buttonTextValue = `${this.buttonLabelBaseValue} for ${formattedAmount}`;
  }

  updateButtonDisabledState() {
    if (this.fmfCheckoutValue) {
      const enabled = this.confirmationValue && this.stripeTokenIdValue;
      this.buttonDisabledValue = !enabled;
    } else {
      // If not fmf checkout (i..e, we are using stripe checkout), the button is always enabled
      this.buttonDisabledValue = false;
    }
  }

  // This hides the "Save 20%" text over the donation type toggle for the annual option if the amount selected is $10 (selectedAmount = 1000)
  // This is because we don't apply a discount for the annual subscription in this case (as we're already giving them a discount)
  updateAnnualSavingVisibility() {
    const selectedAmount = parseInt(this.amountPickerTarget.querySelector('input[type="radio"]:checked').value)

    if (this.hasAnnualSavingTarget) {
      // If the selected amount is $10 (selectedAmount = 1000), we hide the "Save 20" text
      //
      // Check if the selected amount matches any discount's 'to' value
      // The discounts array is defined in discounted_membership_form_component.rb
      // We could just say if (selectedAmount === 1000), but this is more future-proof
      if (this.discountsValue.some(discount => discount.to === selectedAmount)) {
        this.annualSavingTarget.classList.add('hidden')
      } else {
        this.annualSavingTarget.classList.remove('hidden')
      }
    }
  }
}